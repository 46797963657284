import './App.css';
import {HashRouter, Routes, Route} from "react-router-dom";
import AboutComponent from './Pages/About/AboutComponent';
import GalleryComponent from './Pages/Gallery/GalleryComponent';
import ContactComponent from './Pages/Contact/ContactComponent';
import SevaComponent from './Pages/Seva/SevaComponent';
import HeaderComponent from './Global/Header/HeaderComponent';
import { FooterComponent } from './Global/Footer/FooterComponent';


export default function App() {

    return (
        <div>
            <HashRouter> 
                {/* Header */}
                <div>
                <HeaderComponent/>
                </div>

                {/* Router Implementation */}
                <div className='main'>
                <Routes>
                    <Route path="/"
                        element={<AboutComponent/>}></Route>
                    <Route path="/about"
                        element={<AboutComponent/>}></Route>
                    <Route path="/seva" element={<SevaComponent/>}></Route>
                    <Route path="/gallery" element={<GalleryComponent/>}></Route>
                    <Route path="/contact" element={<ContactComponent/>}></Route> 
                </Routes>
                </div>
                {/* Footer */}
                <div>
                <FooterComponent/>
                </div>
            </HashRouter>
        </div>
    );
}
