import "./Contact.css"

export default function ContactComponent(){
return(
    <div className="main">
        <h1 className="heading">Contact Us</h1>
            <div className="box-area">
                <div >
                <iframe className="maps"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d70801.0720436729!2d77.39342635419594!3d13.113968297556578!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaf55273c277d3569!2sMaha%20Ganapathi%20Temple!5e0!3m2!1sen!2sin!4v1674389552999!5m2!1sen!2sin" style={{border:0}}  loading="lazy" referrerPolicy={"no-referrer-when-downgrade"}></iframe>
                </div>
                <div className="content">
                <div style={{padding:20}} >
                    <p>
                        <label><b>Address</b></label> :
                        492V+CHW, Ganesha Temple Street, Jyothi Nagar, Nelamangala Town, Karnataka 562123
                    </p>
                </div>
                {/* <div style={{ background-color: "antiquewhite"}}></div> */}
                <div style={{height:50,backgroundColor:"white",margin:0}}/>
                <div style={{clear:"both",padding:30}}>
                        <label><b>Hours</b></label> :
                            <table>
                                <thead>
                                <tr><th>Days</th><th>Morning</th><th>Evening</th></tr>
                                </thead>
                                <tbody>
                                <tr><td>Monday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                <tr><td>Tuesday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                <tr><td>Wednesday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                <tr><td>Thursday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                <tr><td>Friday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                <tr><td>Saturday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                <tr><td>Sunday</td><td>7 am–12:30 pm</td><td>6:30–9:30 pm</td></tr>
                                </tbody>
                            </table>
                </div>
                </div>
</div>
         </div>
);
}