export const navList= [
// {
//     id: "home",
//     label: "Home",
//     icon: ""
// },
{
    id: "about",
    label: "About",
    icon: ""
},
{
    id: "seva",
    label: "Sevas & Donors",
    icon: ""
},
{
    id: "gallery",
    label: "Gallery",
    icon: ""
},
{
    id: "contact",
    label: "Contact",
    icon: ""
}
]