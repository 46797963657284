import {NavLink} from 'react-router-dom';
import {navList} from './Navigation';
import './Header.css'
import logo from '../../Assets/logo.png'

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useEffect, useState } from 'react';


export default function HeadMenu() {
    const [openMenu,setMenu] = useState(true);

    useEffect(()=>{
        handleHamburgerClick();
    },[])

    /* .........Handle opening and closing of mobile menu .........*/
    function handleHamburgerClick(){
        const ele=document.getElementById("menu-list-mobile");
        console.log(openMenu)
        if(!openMenu){
            setMenu(true)
            ele?.setAttribute("style", `display:block`)
        }
        else{
            setMenu(false)
            ele?.setAttribute("style", `display:none`)
        }
    }

    return (
        <div className='App-header'>

          {/*Logo or Temple Name */}
          <div className='company-name'>
            <img src={logo} alt='' className='logo'></img>
            <h1>MahaGanapathi</h1>
          </div>


          {/* Navigation List */}
            <div > {
                navList.map(item => (
                    <NavLink className='menu-list'
                        key={item.id}
                        to={item.id}>
                        {item.label} </NavLink>
                ))
            } </div>


            {/* Hamburger Button */}
            <div className='hamburger'>
                <button className='hamburger-button' onClick={handleHamburgerClick}>
                    <MenuRoundedIcon></MenuRoundedIcon>
                    </button>
            </div>

            {/*Mobile Navigation Menu */}
            <div id='menu-list-mobile'> {
                navList.map(item => (
                    <NavLink className='menu-list-mobile'
                    onClick={handleHamburgerClick}
                        key={item.id}
                        to={item.id}>
                        {item.label} </NavLink>
                ))
            } </div>



        </div>
    );
}


