import './About.css'


export default function AboutComponent() {

    return (
        <div className='main'>
            <h1 className='heading'>About</h1>
            <div className='layout'></div>
            <div className='content-area'>
                
                <p>
                    Nelamangala shri Mahaganapathi temple has more than 350 years of history. The temple is located in rural Bengaluru.  The main deity here is “Mahaganapathi”, and is  “Swayam Vyakta”.
                </p>
                <p>
                    Veda.Brahma. Shri Chandrashekara Shastri unearthed the idol of shri Mahaganapthi and constructed the temple more than 350 years ago.
                </p>
                <p>
                    Shri Rajarajeswhwari ammanavaru, Sathyabhama Rukmini sametha Chaturbhuja Venugopala swamy, Shrikanteshwara swamy and shri Bhagavadpaada Adi Shankaracharya are other deities in this temple.
                </p>
                <p>
                    The temple was renovated in the year 1997, and complete 25 glorious years after renovation.
                </p>
                <p>
                    Sharannavaratri pooja, Brahmarathotsava, Girija Kalyana, Shankara jayanthi utsava, are some of the major events held in the temple.
                </p>
            </div>
        </div>
    );
}
