import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { imgList } from './Util';
import './Gallery.css'

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function GalleryComponent() {
    const itemData=imgList;
  return (
    <div className='main gallery-container'>
        <h1 className='heading'>Gallery</h1>
    <ImageList
      className='img-list'
      variant="quilted"
      cols={6}
      rowHeight={200}
    >
      {itemData.map((item) => (
        <ImageListItem className='img-items-style'  key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            className='img-style' 
          />
        </ImageListItem>
      ))}
    </ImageList>
    </div>
  );
}
