import "./Seva.css"

export default function SevaComponent() {

    return (
        <div className="main">
            <h1 className="heading">Sevas & Donors</h1>
            <div>
                <div className="dailySevas">
                    <div>
                        <h3>
                            <u>
                                <i>Daily and Monthly Sevas</i>
                            </u>
                        </h3>
                        <ul>
                            <li>Panchamruta Abhisheka</li>
                            <li>Ashtottara</li>
                            <li>Sankashtahara Ganapathi pooja ( every Sankashta)</li>
                            <li>Sathyanarayana pooja (every Poornima)</li>
                        </ul>
                    </div>

                    <div>
                        <h3>
                            <u>
                                <i>Yearly events</i>
                            </u>
                        </h3>
                        <ul>
                            <li>Shankara Jayanthi on Vaishakha Shudha Panchami</li>
                            <li>Sharannavaratri special alankaras and poojas</li>
                            <li>Girija Kalyana during month of Aashvayuja</li>
                            <li>Brahma Rathotsava during month of Aashvayuja</li>
                        </ul>
                    </div>

                </div>

                <div className="paymentsInfo">
                    <h3>
                        <u>
                            <i>Payment options towards seva and donations</i>
                        </u>
                    </h3>

                    Please email us at <b>nelamangalamahaganapathi@gmail.com</b>
                    <br/>
                    <h4>
                        <u>Contact</u>
                        :</h4>
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <b>N.S. Sathyanarayana Shastry</b>
                            </td>
                            <td>:</td>
                            <td>+91 9666080717</td>
                        </tr>
                        <tr>
                            <td>
                                <b>N.S. Ananda Shastry</b>
                            </td>
                            <td>:</td>
                            <td>+91 9964334254</td>
                        </tr>
                        <tr>
                            <td>
                                <b>V. Phanish</b>
                            </td>
                            <td>:</td>
                            <td>+91 9844316333</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>


        </div>
    );
}
