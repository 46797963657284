
// import { GalleryImgs } from "../../Assets"

   const images = importAll(require.context('../../Assets/Gallery', false, /\.(png|jpe?g|svg)$/));
   function importAll(r: __WebpackModuleApi.RequireContext) {
    let images:any = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
     console.log(images)
    return images
   }

export const imgList = [
    {
        id: 1,
        description: "",
        img: images["1.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    },
    {
        id: 2,
        description: "",
        img: images["2.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    },
    {
        id: 3,
        description: "",
        img: images["3.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    },
    {
        id: 4,
        description: "",
        img: images["4.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    }, {
        id: 5,
        description: "",
        img: images["5.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    }, {
        id: 6,
        description: "",
        img: images["6.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    }, {
        id: 7,
        description: "",
        img: images["7.jpeg"],
        title: 'Breakfast',
        rows: 2,
        cols: 2
    }
]
